import React from 'react'
import styled from 'styled-components';

const IntroUC = () => {
    return (
        <Container>
            <ContainerP>Have a look at some ideas you can start with</ContainerP>
        </Container>
    )
}

export default IntroUC

const Container = styled.div`
    margin-top: 2rem;

    @media screen and (min-width: 1000px) {
    margin-top: 0rem;       
    }
`
const ContainerP = styled.p`
    text-align: center;
    font-size: clamp(1rem,3vw, 1.25rem); 
    padding: 0rem 1rem;
    margin-bottom: 2rem;
`
