import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import Img from 'gatsby-image'
import { BsChevronDoubleDown } from 'react-icons/bs';

const HeroUC = () => {
  const data = useStaticQuery(graphql`
    query HeroUCQuery {
        allUsecaseImgJson {
          edges {
            node {
              alt2
              img2 {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      
    `)

  return (
    <Container>
      <Image
        alt={data.allUsecaseImgJson.edges[0].node.alt2}
        fluid={data.allUsecaseImgJson.edges[0].node.img2.childImageSharp.fluid}>
      </Image>
      <Arrow />
    </Container>
  )
}

export default HeroUC

const Container = styled.div`
    margin-top: 70px;
    height: auto;
    
`
const Image = styled(Img)`
    width:65%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1200px) {
      width:60%;
         
      }

`
const Arrow = styled(BsChevronDoubleDown)`
    @media screen and (min-width: 1225px) {
    font-size: 1.8rem;
    color: #000;
    position: absolute;
    top: 45.5rem;
    right: 0;
}
  
`