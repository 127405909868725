import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'


const PharmWrapper = () => {
  const data = useStaticQuery(graphql`
    query PharmWrapperQuery {
        allUsecaseImgJson {
          edges {
            node {
              alt3
              img3 {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        allUsecaseInfoPJson {
          edges {
            node {
              alt
              description
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      
    `)

  const getInfo = (data) => {
    const infoArray = []
    data.allUsecaseInfoPJson.edges.forEach((element, index) => {
      infoArray.push(
        <Cards data-sal="slide-up" data-sal-easing="ease" key={index}>
          <CardImg
            alt={element.node.alt}
            fluid={element.node.img.childImageSharp.fluid}>
          </CardImg>
          {element.node.description.map((item, index) => (
            <CardText key={index}>{item}</CardText>
          ))}
        </Cards>
      )
    })
    return infoArray
  }

  return (
    <Container>
      <ContainerHeader>PHARMACY</ContainerHeader>
      <Image data-sal="slide-left" data-sal-easing="ease"
        alt={data.allUsecaseImgJson.edges[0].node.alt3}
        fluid={data.allUsecaseImgJson.edges[0].node.img3.childImageSharp.fluid}>
      </Image>
      {getInfo(data)}
    </Container>
  )
}

export default PharmWrapper

const Container = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-auto-rows: auto;
    align-items: center;
    padding: 1rem 1rem;
`

const ContainerHeader = styled.h2`
    grid-column: span 12;
    font-size: clamp(1.3rem,3vw, 2rem);
    text-align: center;
    
`

const Image = styled(Img)`
    grid-column: span 12;
    margin: 1rem 1rem;
    
    @media screen and (min-width: 1000px) {
        grid-column: span 8;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
`
const Cards = styled.div`
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #71C4DE;
    grid-row-gap: 10px;
    margin: 1rem 1rem;
    border-radius: 5%;

    @media screen and (min-width: 600px) {
        grid-column: span 6;
    } 
    
    @media screen and (min-width: 1000px) {
        grid-column: span 4;
    }    
`
const CardImg = styled(Img)`
    width: 20%;
    color: #fff;
    
`
const CardText = styled.p`
    font-size: clamp(1rem,2vw, 1rem);
    text-align: center;
    //line-height: 2;
`
