import React from 'react'
import styled from 'styled-components';
import Request from './../Request';
import { useStaticQuery, graphql } from "gatsby"

const LastSection = () => {
    const data = useStaticQuery(graphql`
    query LastSectionQuery {
         allUsecaseInfoRestJson {
          edges {
            node {
              description2
            }
          }
        }
      }
      
    `)

    return (
        <Container>
            <CardWrapper data-sal="slide-down" data-sal-easing="ease">
                <ContainerHeader>POPULATION HEALTH</ContainerHeader>
                {data.allUsecaseInfoRestJson.edges[0].node.description2.map((item, index) => (
                    <CardText key={index}>{item}</CardText>
                ))}
            </CardWrapper>
            <ReqWrapper><Request /></ReqWrapper>
        </Container>
    )
}

export default LastSection

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 800px) {
        display: flex;
        flex-direction: row;
        //align-items: center;
        justify-content: space-around;
    }
`
const CardWrapper = styled.div`
    border: 3px solid #71C4DE;
    border-radius: 5%;
    padding: 1rem 1rem;
    margin: 1rem 1rem;
`

const ContainerHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
`
const ReqWrapper = styled.div`
    max-width: 500px;
`
const CardText = styled.p`
    font-size: clamp(1rem,2vw, 1rem);
    text-align: center;
    line-height: 2; 
`
