import React from 'react'
import styled from 'styled-components';
import CtaUC from './CtaUC'
import HeroUC from './HeroUC'
import HospWrapper from './HospWrapper'
import IntroUC from './IntroUC'
import PharmWrapper from './PharmWrapper'
import ResWrapper from './ResWrapper'
import AssocWrapper from './AssocWrapper'
import LastSection from './LastSection'
import InsuranceWrapper from './InsuranceWrapper'

const Body = () => {
    return (
        <Container>
            <CtaWrapper>
                <CtaUC />
            </CtaWrapper>
            <Hero>
                <HeroUC />
            </Hero>
            <IntroWrapper>
                <IntroUC />
            </IntroWrapper>
            <Section>
                <PharmWrapper />
            </Section>
            <Section>
                <HospWrapper />
            </Section>
            <SectionB>
                <ResWrapper />
            </SectionB>
            <SectionB>
                <AssocWrapper />
            </SectionB>
            <SectionB>
                <InsuranceWrapper />
            </SectionB>
            <SectionC>
                <LastSection />
            </SectionC>
        </Container>
    )
}

export default Body

const Container = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-auto-rows: auto;
`
const CtaWrapper = styled.div`
    grid-row: 2;
    grid-column: span 12;
    
    @media screen and (min-width: 1000px) {
        grid-row: 1;
        grid-column: span 4;
    }
`
const Hero = styled.div`
    grid-column: span 12;
    
    @media screen and (min-width: 1000px) {
        grid-column: span 8;       
    }
`
const IntroWrapper = styled.div`
    grid-column: span 12;
    
`
const Section = styled.div`
    grid-column: span 12;
   
`
const SectionB = styled.div`
    grid-column: span 12;
    
`
const SectionC = styled.div`
    grid-column: span 12;
    margin-top: 3px;  
    background: #BFDEFA;  
`

