import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Body from '../components/usecase/Body'


const Usecase = () => (
    <Layout>
        <SEO title="Health information system kenya utilization- digital health tools"
            description="Use case of various health information systems that can be implemented to improve your health system management, increase your revenue and quality of care. Usecase of digital health technologies"
            keywords="hmis kenya, mobile app, web design kenya, digital marketing in kenya, kenya health information system, mhealth, hospital management system kenya, electronic health record in Kenya, digital health in kenya, hospital management system in kenya,
                    erp, data visualization, dashboard, social media management,erp software, pharmacy software, health system management, pos system kenya, data analytics kenya, inventory control system for pharmacy, usecase, kenya emr, predictive analytics, pharmacy management software, online pharmacy platform kenya"
            img="https://www.tripleaimsoftware.com/static/7539e6ae334dce59f2bd8a05561a917e/46604/HeroUC.png"
            siteUrl="https://www.tripleaimsoftware.com/usecase/">
        </SEO>
        <Body />
    </Layout>
)

export default Usecase
