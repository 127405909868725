import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const ResWrapper = () => {
  const data = useStaticQuery(graphql`
    query ResWrapperQuery {
        allUsecaseImgJson {
          edges {
            node {
              alt5
              img5 {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        allUsecaseInfoRestJson {
          edges {
            node {
              description
            }
          }
        }
      }
      
    `)

  return (
    <Container>
      <ContainerHeader>RESEARCH ORGANIZATION</ContainerHeader>
      <Image
        alt={data.allUsecaseImgJson.edges[0].node.alt5}
        fluid={data.allUsecaseImgJson.edges[0].node.img5.childImageSharp.fluid}>
      </Image>
      <CardWrapper data-sal="slide-right" data-sal-easing="ease">
        {data.allUsecaseInfoRestJson.edges[0].node.description.map((item, index) => (
          <CardText key={index}>{item}</CardText>
        ))}
      </CardWrapper>
    </Container>
  )
}

export default ResWrapper

const Container = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-auto-rows: auto;
       
`
const ContainerHeader = styled.h2`
    grid-column: span 12;
    font-size: clamp(1.3rem,3vw, 2rem);
    text-align: center;
    margin-top: 1rem;
`
const CardWrapper = styled.div`
  grid-column: span 12;
  padding: 0rem 1rem;
  
  @media screen and (min-width: 800px) {
    grid-column: span 6    
  }    
`
const Image = styled(Img)`
    grid-column: span 12;

    @media screen and (min-width: 800px) {
      grid-column: span 6
    }
`
const CardText = styled.div`
    font-size: clamp(1rem,2vw, 1rem);
    text-align: center;
    line-height: 2;
`
