import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const AssocWrapper = () => {
  const data = useStaticQuery(graphql`
    query AssocWrapperQuery {
        allUsecaseImgJson {
          edges {
            node {
              alt6
              img6 {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        allUsecaseInfoRestJson {
          edges {
            node {
              description1
            }
          }
        }
      }
      
    `)

  return (
    <Container>
      <ContainerHeader>ASSOCIATION AND BODIES</ContainerHeader>
      <Wrapper>
        <Image
          alt={data.allUsecaseImgJson.edges[0].node.alt6}
          fluid={data.allUsecaseImgJson.edges[0].node.img6.childImageSharp.fluid}>
        </Image>
        <CardWrapper data-sal="slide-left" data-sal-easing="ease">
          {data.allUsecaseInfoRestJson.edges[0].node.description1.map((item, index) => (
            <CardText key={index}>{item}</CardText>
          ))}
        </CardWrapper>
      </Wrapper>
    </Container>
  )
}

export default AssocWrapper

const Container = styled.div`
    background: #E9F8FE;
  `
const Wrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    
    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "info pic" 
    } 
`
const ContainerHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    text-align: center;
    margin-top: 1rem;
`
const CardWrapper = styled.div`
    
    
  @media screen and (min-width: 800px) {
    grid-area: info;  
  }    
`
const Image = styled(Img)`
  
    @media screen and (min-width: 800px) {
      grid-area: pic;
    }
`
const CardText = styled.div`
    font-size: clamp(1rem,2vw, 1rem);
    text-align: center;
    line-height: 2;
`
