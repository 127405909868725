import React from 'react'
import styled from 'styled-components';
import { Button } from './../Button';
import ctaUCImg from '../../images/ctaUC.png'

const CtaUC = () => {
    return (
        <Container>
            <ContainerP>Ready to try some ideas</ContainerP>
            <ContainerButton><Button primary="true" to='/request'>Get Proposal</Button></ContainerButton>
        </Container>
    )
}

export default CtaUC


const Container = styled.div`
    background: #fff;
     
    @media screen and (min-width: 1000px) {
        background: url(${ctaUCImg}) no-repeat;
        background-size: 100%;
        background-position: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`
const ContainerP = styled.h1`
    margin-bottom: 1rem;
    text-align: center;
    font-size: clamp(1.5rem,2.8vw, 2.2rem);
    margin-top: 1rem;
`
const ContainerButton = styled.div`
    text-align: center;    
    margin-bottom: 1rem;

    @media screen and (min-width: 1000px) {
        margin-right: 7rem;
    }
`